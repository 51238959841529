<template>
  <div class="page-wrapper" v-if="$route.path != '/404'">
    <Header />
    <Error />
    <router-view/>
    <Footer />
    <ScrollToTop />
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import Header from "./components/header";
import { RouterView } from "vue-router";
import Footer from "./components/footer";
import Error from "./components/errorLogs";
import ScrollToTop from "./components/scroll_to_top";

export default {
  components: {
    Header,
    RouterView,
    Error,
    Footer,
    ScrollToTop,
  },
};
</script>

<style>
/* .fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
} */
</style>
