import Cookies from 'js-cookie'

const TokenKey = 'Client-Token'

export function get_token() {
    return Cookies.get(TokenKey)
}

export function set_token(token) {
    return Cookies.set(TokenKey, token)
}

export function remove_token() {
    return Cookies.remove(TokenKey)
}

export function set_remember_login(user) {
    Cookies.set("email_remember", user.email);
    Cookies.set("password_remember", user.password);
    Cookies.set("check_remember", user.check);
    return;
}

export function get_remember_login() {
    return {
        email: Cookies.get("email_remember"),
        password: Cookies.get("password_remember"),
        check: Cookies.get("check_remember"),
    }
}

export function remove_remember_login() {
    Cookies.remove("email_remember");
    Cookies.remove("password_remember");
    Cookies.remove("check_remember")
    return;
}