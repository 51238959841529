<template>
  <div class="nav-box">
    <nav class="navbar navbar-expand-lg navbar-custom nav-header">
      <div class="container">
        <router-link to="/" class="navbar-brand logo">
          <img :src="logo" alt="logo" />
        </router-link>

        <div class="loat-right">
          <div class="collapse navbar-collapse">
            <ul class="navbar-nav menu nav-menu">
              <li class="nav-item dropdown">
                <a href="#" class="nav-link link-menu">
                  Sản phẩm
                </a>
                <ul class="dropdown-menu">
                  <div class="tabs">
                    <div class="nav nav-menu-desktop nav-tabs" id="myTab" role="tablist">
                      <div class="nav-item" role="presentation" v-for="(item, key) in categories" :key="key">
                        <button :class="active == key ? 'nav-link active' : 'nav-link'" :id="item.key"
                          data-bs-toggle="tab" :data-bs-target="item.address" type="button" role="tab"
                          :aria-controls="item.key" :aria-selected="active == key ? true : false" @click="tab(key)">
                          {{ item.name }}
                        </button>
                      </div>
                    </div>
                    <div class="tab-content" id="myTabContent">
                      <div v-for="(item, key) in categories" :key="key" :class="active == key
                        ? 'tab-pane fade show active'
                        : 'tab-pane fade'
                        " :id="item.compare" role="tabpanel" :aria-labelledby="item.key">
                        <div class="row box-menu-product">
                          <div class="col-12 col-sm-6 col-md-4 d-flex item-menu"
                            v-for="(item, key) in getProductsByCategory(item.id)" :key="key">
                            <div class="col-md-2 icon-product">
                              <img :src="$filters.base_api(item.photo)" alt="Course 1" />
                            </div>
                            <div class="col-md-10 p-0 name-product">
                              <router-link class="product-link" :to="$filters.slugify(item.name, '/product', item.id)
                                " @click="toLink">
                                <p class="name-product-title">
                                  {{ item.name }}
                                </p>
                                <p class="name-product-desc" v-if="item.seo_title">
                                  {{ item.seo_title }}
                                </p>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
              <li class="nav-item">
                <router-link class="nav-link link-menu" to="/dich-vu">Dịch vụ</router-link>
              </li>
              <li class="nav-item dropdown">
                <router-link to="/tin-tuc" class="nav-link link-menu">
                  <template v-slot="{ navigate }">
                    <div v-if="!firstNews.id" @click.prevent="navigate">Tin tức</div>
                    <router-link v-else :to="$filters.slugify(firstNews.title, '/tin-tuc/danh-muc', firstNews.id)"
                      class="nav-link link-menu">
                      Tin tức
                    </router-link>
                  </template>

                </router-link>
                <ul class="dropdown-menu news-dropdown">
                  <div class="tabs">
                    <div class="nav nav-menu-desktop nav-tabs border-button-none" id="myTab" role="tablist">
                      <div class="nav-item" role="presentation" v-for="category in categories_news" :key="category.id">
                        <router-link :to="$filters.slugify(category.title, '/tin-tuc/danh-muc', category.id)">{{
                          category.title
                        }}</router-link>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
              <li class="nav-item">
                <router-link class="nav-link link-menu" to="/cong-ty">
                  Công ty
                </router-link>
              </li>
            </ul>
          </div>
        </div>

        <button class="navbar-toggler" type="button" @click="menuMobile">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  </div>

  <Breadcrumb v-if="$route.path != '/'" />
</template>
<script>
import "@/assets/css/nav.css";
import { mapGetters } from "vuex";
import Breadcrumb from "../breadcrumb";
import convertToSnakeCase from "@/helpers/convertToSnakeCase";
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import Logo from "#/assets/logo.png";
import Icon from "#/assets/images/icon.png";

export default {
  components: {
    Breadcrumb,
  },
  computed: {
    ...mapGetters([
      "token",
      "name",
      "user_id",
      "avatar",
      "categories",
      "products",
    ]),
  },
  setup() {
    const store = useStore();

    const categories = computed(() => {
      return store.state.menu.categories;
    });

    const products = computed(() => {
      return store.state.menu.products;
    });

    const categories_news = computed(() => {
      return store.state.category_news.categories_news;
    });

    const getProductsByCategory = (categoryId) => {
      return store.getters["menu/getProductsByCategory"](categoryId);
    };

    let firstNews = {};
    if (typeof store.state.category_news.categories_news[0] == 'object') {
      firstNews = store.state.category_news.categories_news[0]
    }

    onMounted(() => {
      store.dispatch("menu/getCategories");
      store.dispatch("menu/getProducts");
      store.dispatch("category_news/getCategories");
    });
    return { categories, getProductsByCategory, products, categories_news, firstNews };
  },
  data() {
    return {
      product_categories: [],
      filterData: [],
      active: 0,
      block: "block",
      none: "none",
      filterData: {
        category_id: null,
      },
      tabData: [],
      tabSigleData: [],
      category_id: null,
      logo: Logo,
      icon: Icon,
    };
  },
  filters: {
    convertToSnakeCase,
  },
  methods: {
    menuMobile() {
      console.log(1);
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push("/");
    },
    tab(num, id) {
      this.active = num;
    },
    toLink() {
      document.querySelector(".dropdown-menu").style.display = this.none;
    },
    doSomething() {
      console.log(1)
      return false;
    }
  },
};
</script>
