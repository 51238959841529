import Api from "@/api/policy";

const state = {
  polices: [],
};

const mutations = {
  SET_POLICY: (state, polices) => {
    state.polices = polices;
  },
  CLEAR_POLICY: (state) => {
    state.polices = [];
  },
};

const actions = {
  addPolicy({ commit }, polices) {
    commit("SET_POLICY", polices);
  },
  clearPolicy({ commit }) {
    commit("CLEAR_POLICY");
  },
  getPolicy({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      Api.getPolicy({ page: 1, limit: 5 })
        .then((response) => {
          if (response && response.code == 200) {
            const { items } = response.data;

            commit("SET_POLICY", items);
            resolve(response.data);
          } else {
            commit("CLEAR_POLICY", "");
            reject();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
