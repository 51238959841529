<template>
    <Nav />
</template>

<script>
import Nav from "./nav";
export default {
  components: {
    Nav,
  },
};
</script>
