import request from "@/utils/request";

export default {
  getPolicy(query) {
    return request({
      url: "/policy",
      method: "get",
      params: query,
    });
  },
  getPolicyById(id) {
    return request({
      url: "/policy/" + id,
      method: "get",
    });
  },
};
