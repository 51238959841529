const state = {
  base_api: "",
};

const mutations = {
  ADD_BASE_API: (state, base_api) => {
    state.base_api = base_api;
  },
  CLEAR_BASE_API: (state) => {
    state.base_api = "";
  },
};

const actions = {
  addBaseApi({ commit }, base_api) {
    commit("ADD_BASE_API", base_api);
  },
  clearBaseApi({ commit }) {
    commit("CLEAR_BASE_API");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
