<template>
  <div id="breadcrumb">
    <div class="container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <template v-for="(item, index) in levelList" :key="item.path">
            <li v-if="item.redirect === 'noRedirect' || index == levelList.length - 1" class="breadcrumb-item">
              {{ item.meta.title }}
            </li>
            <li v-else class="breadcrumb-item active">
              <router-link :to="item.path"> {{ item.meta.title }} </router-link>
            </li>
          </template>
        </ol>
      </nav>
    </div>
  </div>
</template>

<script>
import bus from '@/bus';
import { watchEffect } from 'vue';
import { useRouter } from 'vue-router';
export default {
  setup() {
    const router = useRouter();
    let matched = [];
    watchEffect(() => {
      if (router.currentRoute.value.matched) {
        matched = router.currentRoute.value.matched;
      }
    })

    return { matched }
  },
  props: {
    pageDetail: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      levelList: null,
    };
  },
  watch: {
    $route(route) {
      if (route.path.startsWith("/redirect/")) {
        return;
      }
      this.getBreadcrumb();
    },
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      let matched = this.matched.filter(
        (item) => item.meta && item.meta.title
      );

      const first = matched[0];

      if (!this.isHome(first)) matched = [{ path: "/", meta: { title: "Trang chủ" } }].concat(matched);

      this.levelList = matched.filter(
        (item) => item.meta && item.meta.title && item.meta.breadcrumb !== false
      );
      if (this.pageDetail.path) this.levelList.push(this.pageDetail);
    },
    isHome(route) {
      const name = route && route.meta.title;
      if (!name) return false;
      return (
        name.trim().toLocaleLowerCase() === "Trang chủ".toLocaleLowerCase()
      );
    },
  },
  beforeUnmount() {
    bus.on('title', val => { })
  }
};
</script>

<style scoped>
#breadcrumb {
  font-family: 'Roboto Medium';
  font-size: 14px;
  padding: 10px;
  color: #222222;
}

#breadcrumb a {
  text-decoration: none;
  color: #222222;
}

.breadcrumb {
  margin-bottom: 0;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: '>'
}
</style>
