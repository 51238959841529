const getters = {
  token: (state) => state.user.token,
  name: (state) => state.user.name,
  user_id: (state) => state.user.user_id,
  avatar: (state) => state.user.avatar,
  errorLogs: (state) => state.error.logs,
  categories: (state) => state.menu.categories,
  base_api: (state) => state.setting.base_api,
  categories: (state) => state.menu.categories,
  products: (state) => state.menu.products,
  polices: (state) => state.policy.polices,
  categories_news: (state) => state.category_news.categories_news,
};

export default getters;
