<template>
  <div class="partner">
    <div class="container">
      <div class="container-box">
        <div class="intro-box text-center">
          <h1 class="intro-title-box">Đối tác liên kết</h1>
          <p class="intro-desc-box">
            Liên kết những đối tác và ngân hàng hỗ trợ thanh toán hàng đầu hiện
            nay, mang lại chi phí tốt cho khách hàng bao gồm Nhà trường và các
            Cơ quan nhà nước
          </p>
        </div>
      </div>
      <div class="container-content" v-if="partners.length > 0">
        <section>
          <div class="container-box-item">
            <div id="owl-partner" ref="carousel" class="owl-carousel owl-theme">
              <article class="thumbnail item item-box" v-for="item in partners" :key="item.id">
                <div class="item-box-container">
                  <div class="caption caption-box">
                    <img :src="$filters.base_api(item.photo)" alt="icon" />
                  </div>
                </div>
              </article>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/partner";
import bannerAri from "#/assets/images/banner/dt_ari.png";
import bannerOcb from "#/assets/images/banner/dt_ocb.png";
import bannerVnpt from "#/assets/images/banner/dt_vnpt.png";
import bannerViettel from "#/assets/images/banner/dt_viettel.png";
import bannerZaloPay from "#/assets/images/banner/dt_zalopay.png";
import $ from "jquery";
import "owl.carousel/dist/owl.carousel.min.js";
import "owl.carousel/dist/assets/owl.carousel.min.css";

export default {
  data() {
    return {
      partners: [],
      bannerAri: bannerAri,
      bannerOcb: bannerOcb,
      bannerVnpt: bannerVnpt,
      bannerViettel: bannerViettel,
      bannerZaloPay: bannerZaloPay,
      total: 0,
    };
  },
  mounted() {
    this.getList();
  },
  beforeUnmount() {
    $(this.$el).find("#owl-partner").owlCarousel("destroy");
  },
  methods: {
    async getList() {
      await Api.list({ action: "all" })
        .then((response) => {
          this.total = response.data.total;
          this.partners = response.data.items;
        })
        .catch((e) => console.log(e))
        .finally(() => {
          this.$nextTick(() => {
            $(this.$el).find("#owl-partner").owlCarousel({
              items: 5,
              loop: true,
              autoplay: true,
              autoplayTimeout: 3000,
              autoplayHoverPause: true,
            });
          });
        });
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/partner.css";
</style>
