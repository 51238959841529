import request from "@/utils/request";

export default {
  list(query) {
    return request({
      url: "/post-news",
      method: "get",
      params: query,
    });
  },
  getnewsById(id) {
    return request({
      url: "/post-news/" + id,
      method: "get",
    });
  },
  getCategoriesNews(query) {
    return request({
      url: "/category-news",
      method: "get",
      params: query,
    });
  },
};
