import request from "@/utils/request";

export default {
  list(query) {
    return request({
      url: "/dev-products",
      method: "get",
      params: query,
    });
  },
}