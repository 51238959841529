<template>
  <div class="hot-product">
    <div class="container-box">
      <h1 class="text-center title-box">Sản phẩm nổi bật</h1>
    </div>
    <div class="content-box" v-if="tabs.length > 0">
      <ul class="nav nav-tabs d-flex justify-content-center" id="myTab" role="tablist">
        <li class="nav-item" role="presentation" id="my-out-img" v-for="(item, key) in tabs" :key="key">
          <button :class="
            active == key
              ? 'nav-link btn-tab-hot active'
              : 'nav-link btn-tab-hot'
          " :id="item.key" data-bs-toggle="tab" :data-bs-target="item.address" type="button" role="tab"
            :aria-controls="item.key" :aria-selected="active == key ? true : false" @click="tab(key)" :style="
              key == active
                ? 'background:' + item.bgColor + ';'
                : 'color:' + item.bgColor
            ">
            <div class="icon-box">
              <img :src="$filters.base_api(item.icon)" :alt="item.name" :style="style(key, item.bgColor)" />{{ item.name
              }}
            </div>
          </button>
        </li>
      </ul>
      <div class="tab-content">
        <div v-for="(item, key) in tabs" :key="key" :class="
          active == key
            ? 'tab-pane fade tab-padding show active'
            : 'tab-pane tab-padding fade'
        " :id="item.compare" role="tabpanel" :aria-labelledby="item.key" :style="
  'background: url(' +
  $filters.base_api(item.background) +
  ') no-repeat center bottom; background-size: 100% 100%; padding-bottom:60px'
">
          <div class="container">
            <div class="box-content-item">
              <div class="row">
                <div class="col-6">
                  <div class="row col-md-12 caption-box">
                    <div class="col-md-12 caption-box-title">
                      <span class="bg-color" :style="'background:' + item.bgColor"></span>
                      <h5>{{ item.title }}</h5>
                    </div>

                    <div class="col-md-10 caption-box-desc">
                      <p>
                        {{ item.desc }}
                      </p>

                      <a v-if="item.link" :href="item.link" target="_blank" rel="noopener noreferrer"
                        :style="'color:' + item.bgColor">Xem thêm chi tiết</a>
                    </div>

                    <div class="row caption-box-list">
                      <div class="col-md-6 caption-box-item" v-for="(data, key) in item.feature" :key="key">
                        <div class="feature-list">
                          <div class="box-icon">
                            <img :src="$filters.base_api(data.icon)" :alt="$filters.base_api(data.icon)" />
                          </div>
                          <div class="box-name">{{ data.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 box-content-img">
                  <img :src="$filters.base_api(item.banner)" :alt="$filters.base_api(item.banner)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import convertToSnakeCase from "@/helpers/convertToSnakeCase";
import Api from "@/api/product";

export default {
  data() {
    return {
      tabs: [],
      active: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      await Api.getOutstanding({ action: "all" })
        .then((response) => {
          if (response.code !== 200) {
            return this.$notify({
              title: "Lỗi!",
              message: response.message,
              type: "error",
            });
          }
          response.data.items.forEach((i) => {
            i.feature = JSON.parse(i.feature);
            i.key = convertToSnakeCase(i.name) + "-tab";
            i.address = "#" + convertToSnakeCase(i.name);
            i.compare = convertToSnakeCase(i.name);
            this.tabs.push(i);
          });
        })
        .catch((e) => console.log(e));
    },
    tab(num) {
      this.active = num;
    },
    style(num, bg) {
      if (num == this.active) {
        return `filter: brightness(0) invert(1);`;
      }
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/hot_product.css";
</style>
