<template>
  <div class="news">
    <div class="container">
      <div class="container-box">
        <div class="intro-box text-center">
          <h3 class="intro-title-box">Tin tức & Tuyển dụng</h3>
        </div>
      </div>
      <div class="container-content" v-if="items.length > 0">
        <section>
          <div class="container-box-item">
            <div class="row">
              <div class="col">
                <div class="box-title">
                  <h5>Tin tức - sự kiện</h5>
                </div>
                <div class="row child">
                  <div class="col-12 col-md-7 box-big-news" v-if="boxBig.length > 0">
                    <div class="row" v-for="(item, key) in boxBig" :key="key">
                      <div class="col-md-12 box-img">
                        <router-link class="link-none" :to="$filters.slugify(item.name, '/tin-tuc', item.id)">
                          <img :src="$filters.base_api(item.photo)" :alt="$filters.base_api(item.photo)" />
                        </router-link>
                      </div>
                      <div class="col-md-12 box-date">
                        <span class="material-icons"> date_range </span>
                        {{ item.created_at }}
                      </div>
                      <div class="col-md-12 box-title">
                        <router-link class="link-none" :to="$filters.slugify(item.name, '/tin-tuc', item.id)">
                          {{ item.name }}
                        </router-link>
                      </div>
                      <div class="col-md-12 box-desc" v-html="item.introduce" v-if="item.introduce"></div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 box-link">
                        <router-link class="link-none link-more" to="">Xem thêm</router-link>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 box-sm col-md-4" v-if="items.length > 0">
                    <div class="row">
                      <div class="col-12" v-for="item in items" :key="item.id">
                        <div class="row box-small">
                          <!-- <router-link class="link-none" :to="$filters.slugify(item.name, '/news', item.id)"> -->
                          <div class="col-md-12 box-img">
                            <router-link class="link-none" :to="
                              $filters.slugify(item.name, '/tin-tuc', item.id)
                            ">
                              <img :src="$filters.base_api(item.photo)" :alt="$filters.base_api(item.photo)" />
                            </router-link>
                          </div>
                          <div class="col-md-12 margin-top-sm box-date">
                            <span class="material-icons"> date_range </span>
                            {{ item.created_at }}
                          </div>
                          <div class="col-md-12 margin-bottom-sm box-title">
                            <router-link class="link-none" :to="
                              $filters.slugify(item.name, '/tin-tuc', item.id)
                            ">
                              {{ item.name }}
                            </router-link>
                          </div>
                          <!-- </router-link> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="box-title">
                  <h5>Tin tuyển dụng</h5>
                </div>
                <div class="row news-other" v-if="items2.length > 0">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-12 fix-margin" v-for="item in items2" :key="item.id">
                        <div class="col-md-12 margin-top-sm box-date">
                          <span class="material-icons"> date_range </span>
                          {{ item.created_at }}
                        </div>
                        <div class="col-md-12 margin-bottom-sm box-title">
                          <router-link class="link-none" :to="$filters.slugify(item.name, '/tin-tuc', item.id)">
                            {{ item.name }}
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 fix-margin box-link">
                    <router-link class="link-none link-more" to="">Xem thêm</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/news";
import Image from "@/assets/images/ki-niem-27-nam.jpg";

export default {
  data() {
    return {
      items: [],
      boxBig: [],
      image: Image,
      filters_news: {
        category_id: 2,
      },
      filters_recruitment: {
        category_id: 3,
      },
      items2: [],
      query1: {
        page: 1,
        limit: 3,
      },
      query2: {
        page: 1,
        limit: 4,
      },
    };
  },
  created() {
    this.getList();
  },
  mounted() { },
  methods: {
    async getList() {
      await Api.list({
        page: 1,
        limit: 3,
        filter: JSON.stringify(this.filters_news),
      }).then((response) => {
        this.items = response.data.items;
      });
      this.items
        .forEach((item, index) => {
          if (index == 0) {
            var i = this.items.indexOf(item);
            if (i > -1) {
              this.items.splice(index, 1);
            }
            this.boxBig.push(item);
          }
        })

      await Api.list({
        page: 1,
        limit: 4,
        filter: JSON.stringify(this.filters_recruitment),
      })
        .then((response) => {
          this.items2 = response.data.items;
        })
        .catch((e) => console.log(e));
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/icon.css";
@import "@/assets/css/news_post.css";
</style>
