import request from "@/utils/request";

export default {
  getListSlideshow(query) {
    return request({
      url: "/slideshow",
      method: "get",
      params: query,
    });
  },
};
