import { createApp } from 'vue';
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate'

import getters from './getters';

const modulesFiles = require.context('./modules', true, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = modulesFiles(modulePath)
    modules[moduleName] = value.default
    return modules
}, {})

const store = createStore({
    modules,
    getters,
    plugins: [createPersistedState()]
});

const app = createApp();
app.use(store);

export default store;