import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/home";
import Policy from "@/views/policy";
import { nextTick } from "vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { title: "Trang chủ" },
  },
  {
    path: "/chinh-sach/:id/:slug",
    name: "chinh-sach",
    component: Policy,
    meta: { title: "Chính sách" },
    props: true,
  },
  {
    path: "/product",
    name: "products",
    component: () => import("@/views/products/index"),
    meta: { title: "Sản phẩm" },
    children: [
      {
        path: "",
        name: "product",
        component: () => import("@/views/products/product"),
        meta: { title: "abc" },
      },
      {
        path: ":id",
        name: "product-detail",
        children: [
          {
            path: ":slug",
            name: "product-detail-page",
            component: () => import("@/views/products/detail"),
            // meta: { title: "Tên sản phẩm" },
          },
        ],
      },
    ],
  },
  {
    path: "/dich-vu",
    name: "dich-vu",
    meta: { title: "Dịch vụ" },
    component: () => import("@/views/services"),
  },
  {
    path: "/tin-tuc",
    name: "tin-tuc",
    redirect: "/404",
    meta: { title: "Tin tức" },
    children: [
      {
        path: "danh-muc",
        name: "category-news",
        redirect: "/404",
        children: [
          {
            path: ":id",
            name: "category-news-detail",
            children: [
              {
                path: ":slug",
                name: "category-news-detail-page",
                component: () => import("@/views/news"),
              },
            ],
          },
        ],
      },
      {
        path: ":id",
        name: "news-detail",
        children: [
          {
            path: ":slug",
            name: "news-detail-page",
            component: () => import("@/views/news/detail"),
            meta: { title: "Tin tức" },
          },
        ],
      },
      {
        path: "tuyen-dung",
        name: "tuyen-dung",
        children: [
          {
            path: "",
            name: "tuyen-dung-children",
            meta: { title: "Tuyển dụng" },
            component: () => import("@/views/news/recruitment"),
          },
          {
            path: ":id/:slug",
            name: "tuyen-dung-chi-tiet",
            meta: { title: "Tuyển dụng" },
            component: () => import("@/views/news/detail"),
          },
        ],
      },
    ],
  },
  {
    path: "/cong-ty",
    name: "cong-ty",
    meta: { title: "Công ty" },
    component: () => import("@/views/about"),
  },
  {
    path: "/lien-he",
    name: "lien-he",
    meta: { title: "Liên hệ" },
    component: () => import("@/views/contact"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
  {
    path: "/ve-cong-ty",
    name: "ve-cong-ty",
    meta: { title: "Về công ty" },
    // component: () => import("@/views/test"),
  },
  {
    path: "/test",
    name: "test",
    meta: { title: "test" },
    component: () => import("@/views/test"),
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  linkActiveClass: "active",
  linkExactActiveClass: "is-active",
  scrollBehavior() {
    return { top: 0 };
  },
});

router.afterEach((to, from) => {
  nextTick(() => {
    if (to.meta.title) {
      document.title =
        process.env.VUE_APP_TITLE + " | " + to.meta.title ||
        "Phần mềm hiệu quả";
    }
  });
});

export function resetRouter() {
  router.go(0);
}

export default router;
