<template>
  <div id="carouselExampleIndicators" class="carousel slide slideshow" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button v-for="(item, key) in slideshow" :key="key" type="button" data-bs-target="#carouselExampleIndicators"
        :data-bs-slide-to="key" :class="key == 0 ? 'btn-slide active' : 'btn-slide'" aria-current="true"
        :aria-label="'Slide ' + (key + 1)" />
    </div>
    <div class="carousel-inner">
      <div :class="key == 0 ? 'carousel-item active' : 'carousel-item'" v-for="(item, key) in slideshow" :key="key">
        <img :src="$filters.base_api(item.photo)" class="d-block w-100" :alt="$filters.base_api(item.photo)" />
        <div class="carousel-caption d-none d-md-block text-left">
          <p v-html="item.introduce"></p>
          <router-link v-if="item.link" class="btn btn-lg btn-primary btn-slide border-radius-default" to="/">Tìm hiểu thêm</router-link>
        </div>
      </div>
    </div>
    <button v-if="1 == 2" class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
      data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button v-if="1 == 2" class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
      data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
import Api from "@/api/gallery";

export default {
  data() {
    return {
      slideshow: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      await Api.getListSlideshow(this.query)
        .then((response) => {
          this.slideshow = response.data.items;
        })
        .catch((e) => console.log(e));
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/slideshow.css";
</style>
