<template>
  <div class="scroll_to_top" @click="toTop" v-show="scY > 300">
    <span class="material-icons"> upgrade </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scTimer: 0,
      scY: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.scroll_to_top {
  width: 41px;
  height: 41px;
  padding: 10px 10px 10px 10px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background: #110202ad;
  border-radius: 2px;
  position: fixed;
  bottom: 65px;
  right: 25px;
  z-index: 10;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

.scroll_to_top:hover {
  background: #007FF3;
  transition: color 0.2s ease-in-out;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
