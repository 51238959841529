import productService from "@/api/product";

const state = {
  categories: [],
  products: [],
};

const getters = {
  getCategoryById: (state) => (id) => {
    return state.categories.find(category => category.id === id)
  },
  getProductsByCategory: (state) => (categoryId) => {
    return state.products.filter(product => product.category_id === categoryId)
  },
  products: (state) => state.products,
}

const mutations = {
  SET_CATEGORIES: (state, category) => {
    state.categories = category;
  },
  SET_PRODUCTS: (state, product) => {
    state.products = product;
  },
};

const actions = {
  addCategories({ commit }, category) {
    commit("SET_CATEGORIES", category);
  },
  getCategories({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      productService.getProductCategory({ action: "all" })
        .then((response) => {
          if (response && response.code == 200) {
            const { items } = response.data;
            
            commit("SET_CATEGORIES", items);
            resolve(response.data);
          } else {
            commit("SET_CATEGORIES", "");
            reject();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  addProducts({ commit }, product) {
    commit("SET_PRODUCTS", product);
  },
  getProducts({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      productService.getProduct({ action: "all" })
        .then((response) => {
          if (response && response.code == 200) {
            const { items } = response.data;
            commit("SET_PRODUCTS", items);
            resolve(response.data);
          } else {
            commit("SET_PRODUCTS", "");
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
