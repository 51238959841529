import request from "@/utils/request";

export default {
  getProductCategory(query) {
    return request({
      url: "/category-products",
      method: "get",
      params: query,
    });
  },
  getProduct(query) {
    return request({
      url: "/post-products",
      method: "get",
      params: query,
    });
  },
  getProductById(id) {
    return request({
      url: "/post-products/" + id,
      method: "get",
    });
  },
  getOutstanding(query) {
    return request({
      url: "/outstand",
      method: "get",
      params: query,
    });
  },
}