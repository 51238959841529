import UserService from "@/api/user";
import { set_token, remove_token } from "@/utils/auth";
import { resetRouter } from "@/router";

const state = {
  token: "",
  name: "",
  user_id: "",
  avatar: "",
};

const getters = {
  user_id: (state) => state.user_id,
  user_name: (state) => state.name,
  token: (state) => state.token,
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_USER_ID: (state, user_id) => {
    state.user_id = user_id;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
};

const actions = {
  set_token({ commit }, token) {
    if (token) {
      commit("SET_TOKEN", token);
      set_token(token);
    }
  },
  login({ commit }, userInfo) {
    const { email, password } = userInfo;
    return new Promise((resolve, reject) => {
      UserService.login({
        email: email.trim(),
        password: password,
      })
        .then((response) => {
          if (response.status === 200) {
            const { data } = response;
            commit("SET_TOKEN", data.token);
            set_token(data.token);
            resolve();
          } else {
            reject(response.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  info({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      UserService.info()
        .then((response) => {
          if (response && response.code == 200) {
            const { name, user_id, avatar } = response.data;
            commit("SET_NAME", name);
            commit("SET_USER_ID", user_id);
            commit("SET_AVATAR", avatar);
            resolve(response.data);
          } else {
            commit("SET_NAME", "");
            commit("SET_USER_ID", "");
            commit("SET_AVATAR", "");
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      UserService.logout(state.token)
        .then(() => {
          commit("SET_TOKEN", "");
          remove_token();
          resetRouter();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      remove_token();
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
