<template>
  <div class="feature">
    <div class="container">
      <div class="container-box">
        <h1 class="text-center title-box">Dịch vụ hậu mãi, tư vấn tốt nhất</h1>
        <p class="text-center desc-box">
          “Dịch vụ Bảo hành và Bảo trì phần mềm hàng năm - Trọn gói, chi phí
          thấp”
        </p>
        <div class="row box-intro" v-if="features.length > 0">
          <div class="col-sm-3 mb-3 item-box" v-for="item in features" :key="item.id">
            <div class="row item-caption">
              <div class="col-sm-11 icon-box">
                <div class="feature-image">
                  <img :src="$filters.base_api(item.photo)" :alt="$filters.base_api(item.photo)" />
                </div>
              </div>
              <div class="col-sm-11 desc-box">
                <div class="title-item-box">
                  <h5>{{ item.title }}</h5>
                </div>
                <div class="desc-item-box">
                  <ul class="list-item">
                    <li v-for="(data, key) in JSON.parse(item.introduce)" :key="key"> {{ data.name }} </li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-11" v-if="item.link">
                <a :href="item.link" class="view-detail">Xem chi tiết</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/feature";

export default {
  data() {
    return {
      features: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      await Api.list({
        page: 1,
        limit: 4,
      })
        .then((response) => {
          this.features = response.data.items;
        })
        .catch((e) => console.log(e));
    },
  },
};
</script>
<style scoped>
@import "@/assets/css/feature.css";
</style>
