<template>
  <div class="dev-product">
    <div class="container">
      <div class="container-box">
        <div class="intro-box text-center">
          <h1 class="intro-title-box">Sản phẩm phát triển</h1>
          <p class="intro-desc-box">
            Số hóa công tác điều hành, định hướng chương trình Chuyển đổi số
            quốc gia. Đồng thời xây dựng kho cơ sở dữ liệu đáp ứng nhu cầu khai
            thác của cơ quan khi có yêu cầu.
          </p>
          <router-link to="/" class="intro-link-box">Xem thêm chi tiết</router-link>
        </div>
      </div>
      <div class="container-content" v-if="dev_product.length > 0">
        <section>
          <div class="container-box-item">
            <div id="owl-dev-product" ref="carousel" class="owl-carousel owl-theme">
              <article class="thumbnail item item-box" v-for="(item, index) in dev_product" :key="index">
                <div class="item-box-container">
                  <div class="container-img">
                    <img :src="$filters.base_api('/storage/images/layer2.png')" alt="Image 1" class="image image1" />
                    <img :src="$filters.base_api('/storage/images/icon6.png')" alt="Image 2" class="image image2" />
                    <img :src="$filters.base_api('/storage/images/icon4.png')" alt="Image 3" class="image image3" />
                  </div>
                  <div class="caption caption-box">
                    <h4>{{ item.category_name }}</h4>
                    <p class="flex-text">
                      {{ item.desc }}
                    </p>
                    <router-link class="btn btn-primary btn-more" to="/">Xem thêm</router-link>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/dev_product";
import $ from "jquery";
import "owl.carousel/dist/owl.carousel.min.js";
import "owl.carousel/dist/assets/owl.carousel.min.css";

export default {
  data() {
    return {
      dev_product: [],
    };
  },
  watch: {
    dev_product() { },
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      await Api.list({ action: "all" })
        .then((response) => {
          this.dev_product = response.data.items;
        })
        .catch((e) => console.log(e))
        .finally(() => {
          this.$nextTick(() => {
            $(this.$el).find("#owl-dev-product").owlCarousel({
              items: 4,
              autoplay: true,
              loop: true,
              autoplayTimeout: 4000,
              autoplayHoverPause: true,
              margin: 15,
            });
          });
        });
    },
  },
  beforeUnmount() {
    $(this.$el).find("#owl-dev-product").owlCarousel("destroy");
  },
};
</script>

<style scoped>
@import "@/assets/css/dev_product.css";
</style>
