import newsService from "@/api/news";

const state = {
  categories_news: [],
};

const mutations = {
  SET_CATEGORIES: (state, category) => {
    state.categories_news = category;
  },
};

const actions = {
  getCategories({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      newsService
        .getCategoriesNews({ action: "all" })
        .then((response) => {
          if (response && response.code == 200) {
            const { items } = response.data;
            commit("SET_CATEGORIES", items);
            resolve(response.data);
          } else {
            commit("SET_CATEGORIES", "");
            reject();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
