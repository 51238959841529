<template>
  <Slideshow />
  <Feature />
  <HotProduct />
  <DevProduct />
  <Partner />
  <NewsPost />
</template>

<script>
import Slideshow from "./slideshow"
import Feature from "./feature"
import HotProduct from "./hot_product"
import DevProduct from "./dev_product"
import Partner from "./partner"
import NewsPost from "./news_post"

export default {
  components: {
    Slideshow,
    Feature,
    HotProduct,
    DevProduct,
    Partner,
    NewsPost,
  }
}
</script>