<template>
  <div class="policy-detail">
    <div class="container">
      <div class="row">
        <div class="col-md-9">
          <h1 class="box-title-desc pt-3 pb-3 p-0">{{ items.title }}</h1>
        </div>
        <div class="col-md-3"></div>
      </div>
      <div class="row">
        <div class="col-md-9">
          <p v-html="items.introduce" />
        </div>
        <div class="col-md-3">
          <h1 class="box-title-desc policy-hot-title">Liên quan</h1>
          <div class="box-hot">
            <router-link v-for="item in polices" :key="item.id"
              :to="$filters.slugify(item.title, '/chinh-sach', item.id)">
              <h5 v-if="item.id != id">{{ item.title }}</h5>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/policy";
import { mapGetters } from "vuex";
export default {
  props: ["id"],
  data() {
    return {
      policyId: this.id,
      items: [],
    };
  },
  computed: {
    ...mapGetters(["polices"]),
  },
  watch: {
    "$route.params.id": {
      handler() {
        this.policyId = this.$route.params.id;
        this.getList();
      },
      immediate: true,
    },
  },
  methods: {
    async getList() {
      await Api.getPolicyById(this.policyId)
        .then((res) => {
          this.items = res.data.items;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/policy_detail.css";
</style>
