import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './access';
import './notification';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.esm';

const app = createApp(App);

const filterContext = require.context('./filters', false, /\.js$/)
const filters = {}
filterContext.keys().forEach((key) => {
  const name = key.replace(/\.\/(.*)\.js/, '$1')
  filters[name] = filterContext(key).default || filterContext(key)
})

app.config.globalProperties.$filters = filters;
app.use(router);
app.use(store);

app.mount('#app');

