<template></template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["errorLogs"]),
  },
  created() {
    this.showToast();
  },
  methods: {
    showToast() {
    },
  },
};
</script>
