export default function convertToSnakeCase(str) {
  // Chuyển đổi chuỗi thành lowercase
  str = str.toLowerCase();

  // Thay thế các ký tự có dấu thành ký tự không dấu
  str = str.replace(/[áàảãạâấầẩẫậăắằẳẵặ]/g, "a");
  str = str.replace(/[éèẻẽẹêếềểễệ]/g, "e");
  str = str.replace(/[íìỉĩị]/g, "i");
  str = str.replace(/[óòỏõọôốồổỗộơớờởỡợ]/g, "o");
  str = str.replace(/[úùủũụưứừửữự]/g, "u");
  str = str.replace(/[ýỳỷỹỵ]/g, "y");
  str = str.replace(/[đ]/g, "d");

  // Thay thế khoảng trắng, dấu gạch ngang và dấu gạch dưới bằng dấu gạch dưới
  str = str.replace(/[\s-]+/g, "_");

  return str;
}
