<template>
  <link rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css" />
  <div class="footer-trial">
    <div class="trial-desc">Trải nghiệm ứng dụng QLTC - Phụ huynh</div>
    <div class="trial-title">Thanh toán học phí trực tuyến</div>
    <div class="trial-link">
      <button type="button" class="btn btn-dark btn-black">
        <img :src="googlePlay" :alt="googlePlay" />
      </button>
      <button type="button" class="btn btn-primary btn-blue">
        Trải Nghiệm
      </button>
    </div>
  </div>
  <div class="footer-menu">
    <div class="container footer-container">
      <div class="footer-item">
        <div class="footer-item-box">
          <div class="title">Nhóm sản phẩm</div>
          <div class="content">
            <router-link to="/">Tài chính - Kế toán</router-link>
            <router-link to="/">Nguồn lực & Trường học</router-link>
            <router-link to="/">Quản lý nhà nước</router-link>
            <router-link to="/">Doanh nghiệp</router-link>
          </div>
        </div>
        <div class="footer-item-box">
          <div class="title">Các chính sách</div>
          <div class="content">
            <router-link v-for="item in polices" :key="item.id" :to="$filters.slugify(item.title, '/chinh-sach', item.id)"
              class="link-site">
              {{ item.title }}
            </router-link>
          </div>
        </div>
        <div class="footer-item-box">
          <div class="title">Liên hệ</div>
          <div class="content">
            <router-link to="" class="link-site">Tin tức</router-link>
            <router-link to="" class="link-site">Giới thiệu</router-link>
            <router-link to="" class="link-site">Tuyển dụng</router-link>
            <router-link to="" class="link-site">Dịch vụ hỗ trợ</router-link>
          </div>
        </div>

        <div class="footer-item-box">
          <div class="row d-flex flex-wrap tab-pane">
            <div class="col-sm-7">
              <router-link class="btn-contact" to="/lien-he">
                <div class="tab-title-contact">Thông tin liên hệ</div>
              </router-link>
              <div class="tab-caption">
                <table>
                  <tr>
                    <td class="icon">
                      <span class="material-symbols-outlined"> call </span>
                    </td>
                    <td>029 2394 1789</td>
                  </tr>
                  <tr>
                    <td class="icon">
                      <span class="material-symbols-outlined"> mail </span>
                    </td>
                    <td>
                      <a href="mailto: bmarketing@dtsoft.vn">bmarketing@dtsoft.vn</a>
                    </td>
                  </tr>
                  <tr>
                    <td class="icon">
                      <span class="material-symbols-outlined"> public </span>
                    </td>
                    <td>
                      <router-link to="/">www.dtsoft.vn</router-link>
                    </td>
                  </tr>
                  <tr>
                    <td class="icon">
                      <span class="bi bi-facebook icon-bs"></span>
                    </td>

                    <td>DTSoft Software</td>
                  </tr>
                  <tr>
                    <td class="icon">
                      <span class="icon-img">
                        <img :src="iconZalo" alt="" />
                      </span>
                    </td>

                    <td>DTSOFT Phần mềm hiệu quả</td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="col-sm-5">
              <div class="tab-title-product">Hỗ trợ sản phẩm</div>
              <div class="tab-caption">
                <table>
                  <tr>
                    <td class="icon">
                      <img :src="iconMailSp" class="footer-icon-img" alt="icon mail" />
                    </td>
                    <td>
                      <a href="mailto: bmarketing@dtsoft.vn">sales@dtsoft.vn</a>
                    </td>
                  </tr>
                  <tr>
                    <td class="icon">
                      <img :src="iconMailSp" class="footer-icon-img" alt="icon mail" />
                    </td>
                    <td>
                      <a href="mailto: bmarketing@dtsoft.vn">cskh@dtsoft.vn</a>
                    </td>
                  </tr>
                  <tr>
                    <td class="icon">
                      <img :src="iconMailSp" class="footer-icon-img" alt="icon mail" />
                    </td>
                    <td>
                      <a href="mailto: bmarketing@dtsoft.vn">support@dtsoft.vn</a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer-about">
    <div class="container footer-container">
      <div class="row">
        <div class="col-md-2 logo">
          <div class="col-md-12 logo-img">
            <router-link to="/" class="logo-brand logo">
              <img :src="logo" alt="logo" />
            </router-link>
          </div>
          <div class="col-md-12 copy-right">
            &copy; Copyright DTSoft {{ new Date().getFullYear() }}
          </div>
        </div>
        <div class="col-md-6 company">
          <h5 class="company-name">
            Công Ty TNHH Phát triển và chuyển giao phần mềm
          </h5>
          <p class="company-desc">
            Trụ sở: Số 49/203, Kim Ngưu. P. Thanh Lương , Q. Hai Bà Trưng, Tp Hà
            Nội
          </p>
          <p class="company-desc">
            Mã số thuế: 0101148771 - Ngày cấp: 26/06/2001, thay đổi lần thứ 3,
            16/12/2020
          </p>
          <p class="company-desc">
            Nơi cấp: Phòng kinh doanh, Sở kế hoạch và đầu tư Thành phố Hà Nội
          </p>
        </div>
        <div class="col-md-2 company">
          <h5 class="company-name">Chứng nhận bởi</h5>
          <div class="img-certi">
            <img :src="iconIaf" :alt="iconIaf" />
            <img :src="iconIso" :alt="iconIso" />
            <img :src="iconUkas" :alt="iconUkas" />
          </div>
        </div>
        <div class="col-md-2 banner-noty">
          <div class="sale-noti">
            <a href="http://online.gov.vn/Home/WebDetails/99830" target="_blank">
              <img alt='' title='' :src='iconNotify' />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <div class="plugin"></div>
</template>
<script>
import { useStore } from "vuex";
import { mapGetters } from "vuex";
import { onMounted } from "vue";
import Logo from "#/assets/logo.png";
import IconIaf from "#/assets/images/banner/iaf.svg";
import IconIso from "#/assets/images/banner/iso.png";
import IconUkas from "#/assets/images/banner/ukas.png";
import IconMailSp from "#/assets/images/icon/mailSP.png";
import IconZalo from "#/assets/images/icon/icon_zalo.png";
import IconNotify from "#/assets/images/banner/saleNoti.png"
import GooglePlay from "#/assets/images/icon/google_play.png";

export default {
  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch("policy/getPolicy");
    });
  },
  data() {
    return {
      logo: Logo,
      iconIaf: IconIaf,
      iconIso: IconIso,
      iconUkas: IconUkas,
      iconMailSp: IconMailSp,
      iconZalo: IconZalo,
      googlePlay: GooglePlay,
      iconNotify: IconNotify,
      policy_items: [],
    };
  },
  computed: {
    ...mapGetters(["polices"]),
  },
};
</script>

<style scoped>
@import "@/assets/css/footer.css";
</style>
